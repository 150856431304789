.card {
    width: 100%;
    max-width: 350px;
    min-width: 300px;
    height: fit-content;
    min-height: 290px;
    border-radius: 8px;
    border: 1px solid rgba(199, 211, 224, 0.62);
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding-bottom: 20px;
    box-shadow: 1px 0 11px 0 rgba(227, 227, 227, 0.2);
}
/*
.card:last-child {
    margin: 0;
}*/

.head {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid #EBF1FA;
    padding: 20px 10px 15px 15px;
}

.card-logo {
    border: 3px solid #F6F9FF;
    width: 70px;
    height: 70px;
    border-radius: 12px;
}

.card-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0 0 0 15px;
}

.company-name {
    color: #012970;
    font-size: 15px;
    font-weight: 600;
}

.company-slogan {
    color: #6D6D6D;
    font-size: 13px;
    padding-top: 10px;
}

.card-body {
    height: fit-content;
}

@media (max-width: 366px) {
    .card{
        min-width: 100px;
    }
}