.navbar {
    background-color: white;
    display: flex;
    position: fixed;
    width: 100%;
    /*z-index: 100;*/
    align-items: center;
    padding: 13px 25px 13px 25px;
    height: 64px;
    min-width: fit-content;
    border-bottom: 2px solid #EBF1FA;
    justify-content: space-between;
    Z-INDEX: 1002 !important;
    > * {
        height: fit-content;
        width: fit-content;
        font-size: 15px;
    }
}

.title:hover{
    cursor: pointer;
}

.title{
    display: flex;
    align-items: center;
    font-family: "Nunito", serif;
    min-width: fit-content;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    color: #012970;
    padding: 8px;
    letter-spacing: 1px;

    & img {
        width: 40px;
        height: 40px;
        padding: 6px;
    }
}
.mobile{
    width: 35%;
    background: none;
    border: none;
    right: 100px;
}

.actions {
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.actions-button {
    background-color: transparent;
    border: none;
    color: #012970;
    height: 38px;
    width: fit-content;
}

.actions-button:hover {
    color: #1c1c1c;
}

.moon-icon {
    transform: rotate(-30deg);
    margin-right: 18px;
}

.icon {
    font-size: 1.5em !important;
}

.logo-span {
    color: #0FA4F8;
    font-weight: 500;
}

.basic-multi-select {
    width: 140px;
}

.wraper {
    width: 100%;
}
@media (max-width: 760px) {
    .navbar{
        padding-left: 10px;
    }
}
