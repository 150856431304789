.filters-container {
    background-color: #FFF;
    min-width: 320px;
    max-width: 500px;
    height: 100%;
    border-radius: 6px;
    text-align: center;
    overflow: auto;
    padding-bottom: 5px;
    border: 1px solid rgba(199, 211, 224, 0.62);
    opacity: 0.1;
    /*transform: translateX(0px);*/
    /*transition: opacity 0.1s ease-in, transform 0.3s ease-in-out;*/
    margin-left: 15px;
    z-index: 1000;
}

.adding {
    opacity: 1;
}

.removing {
    opacity: 0;
    display: none;
    transform: translateX(15px);
}

.filters-title {
    color: #012970;
    border-bottom: 1px solid #EBF1FA;
    padding: 12px 142px 12px 142px;
    margin-bottom: 13px;
    font-weight: 500;
}

.reset-btn {
    background-color: #569df1;
    border: solid 2px #1E90FF;
    width: fit-content;
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    margin-bottom: 15px;
    font-size: 12px;
}

.filters-body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0 15px 0 15px;
    height: 600px;
    overflow: auto;
    /*transform: translateX(0px);*/
    /*transition: opacity 0.1s ease-in, transform 0.3s ease-in-out;*/
}

.main-filter {
    margin-bottom: 15px;
}

.filter-drowpdowns-container {
    height: 600px;
    overflow: auto;
    transform: translateX(0px);
    transition: opacity 0.1s ease-in, transform 0.3s ease-in-out;
}
.filters-header{
    display: flex;
    align-items: center;

}
.toggle-filters-btn{
    margin-left:16px ;
    align-self: center;
    width: 20px;
    height: 20px;
}
@media (max-width: 767px) {
    .filters-container{
        min-width: revert;
        max-width: revert;
        position: absolute;
        margin: 0;
        top:250px;
        bottom  : 0;
        right   : 0;
        left  : 0;
        width: 100%;

    }
    .filters-header{
        margin-bottom: 16px;

    }
    .filters-title{
        font-weight: 400;
        font-size: 17px;
        line-height: 19px;
        width: 100%;
        /*margin-left: 0%;*/
        margin-top: 0;
        margin-bottom: 0;


    }

}
@media (max-width: 400px) {
    .filters-title{
        /*width: 60%;*/
        margin-right: 200px;
    }
}