.search-bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    background-color: #fafafa;
    border-radius: 8px;
    position: relative;
    z-index: 1001 !important;
    /*right: 100px;*/
    border: 1px solid #ececec;
}

.search-bar {
    display: flex;
    width: 100%;
    height: 34px;
    padding: 6px 12px 6px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    background-color: #fafafa;
    color: black;
    font-size: 11px;
    z-index: 1001 !important;
}

.search-bar:focus, .focused {
    background-color: #EBF1FA;
    color: black;
    border: none;
}

.search-bar:focus {
    outline: none !important;
    z-index: 1001 !important;
}

.search-icon {
    color: #666666;
    margin: 8px;
    z-index: 1001 !important;
}
@media (max-width: 767px) {
    .search-bar-container{
        height: 44px !important;
        background-color: #EBF1FA;

    }
    .search-bar{
        background-color: #EBF1FA;
    }

    .search-bar:focus, .focused {
        background-color: #FFFFFF;
        color: black;
        border: none;
    }
}