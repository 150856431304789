.search-window-navbar-overlay{
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 101;
}
.search-window-overlay {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: 200%;
    /*height: calc(100% - 4em);*/
    background-color: rgba(0, 0, 0, 0.08);
    z-index: 999;
}

.search-window {
    position: fixed;
    top: 50px;
    /*left: 50%; !* Center horizontally *!*/
    /*transform: translateX(-50%);
    /*max-width: 600px; */
    background-color: #EBF1FA;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    z-index: 1001;
    max-height: 500px;
    border-top: 1px solid #1f1f3a;
    width: 100%;
    /*position: relative;*/
    /*right: 170px;*/
}

.header-last{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 20px;
    margin-bottom: 10px;
    align-items: baseline;
}
.text {
    font-size:12px;
    color: #6D6D6D;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 18px;
}

.categories {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.category-button {
    background-color: #91C8E4;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
}

.category-button:hover {
    background-color: #ddd;
}

.last-search-and-results {
    /*border-top: 1px solid #eaeaea;   padding-top: 10px;*/
}

.item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}
.wraper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}
.item-left{
    position: relative;
    top:5px;
}
.item:last-child {
    margin-bottom: 0;
}

.remove-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #6D6D6D;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    height: 100%;
}

.remove-button:hover {
    text-decoration: none;
}
.item-text{
    color: #2D2D2D;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}
.history-icon{
    /*width: 14px;*/
    height: 14px;
    margin-right: 4px;
}
.item-text-button{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}