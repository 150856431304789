.dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px 0 5px;
    border-radius: 8px;
    background-color: rgba(230, 237, 249, 0.67);
    font-size: 13px;
    color: #012970;
}

.filter-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #012970;
    font-size: 12px;
}

.closed {
    display: none;
}

.options-list {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: fit-content;
    padding-left: 15px;

    > * {
        position: static;
    }
}

.dropdown {
    margin-bottom: 5px;
}