.Not-found-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 30px;
}

.not-found-img {
    width: 40%;
    height: 30%;
}

.title-not-fround {
    font-weight: 500;
    font-size: 30px;
    line-height: 19px;
    color: #012970;
    padding: 30px 0 10px;
    text-align: center;
}

.body-not-found {
    color: #55617c;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    padding-top: 10px;
}

@media (max-width: 444px) {
    .title-not-fround{
        text-align: center;
        font-size:24px;
        line-height: 22px;
    }
}