@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(0.6vw + 1.2vh);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, #root {
  height: 100%;
  width: 100%;
  background-color: #EBF1FA;
}
.page-wrapper{
  height: 100vh;
  width: 100%;
  /*padding: 25px;*/
  position: static;
  overflow: auto;
  /*
  background-color: #EBF1FA;
  */
  background-color: #F6F9FF;
}
.home_page{
  padding: 25px;
}
::-webkit-scrollbar {
  width: 10px;
  background: #f1f1f1;
}