.header {
    display: flex;
    color: #012970;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 55px;
    margin-bottom: 11px;
    padding-left: 10px;
    min-width: 388px;
    .sub-header {
        font-size: 15px;
    }
}

.header img {
    margin-right: 5px;
}

.feedback-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 6px;
    padding: 25px 12px 25px 15px;
    /*
    padding: 1.5em 0.5em 1.5em 0.5em;
    */
    min-width: 360px;
    border: 1px solid rgba(199, 211, 224, 0.62);
    margin-bottom: 13px;
}

.feedback-message {
    color: #012970;
    font-size: 13px;
    font-weight: 500;
    height: fit-content;
}

.filter-button {
    display: flex;
    border-radius: 8px;
    background-color: #569df1;
    border: solid 2px #1E90FF;
    color: white;
    padding: 2px 5px 2px 5px;
    width: fit-content;
    height: fit-content;
    margin: 5px;
    align-items: center;
    min-width: 120px;
    align-content: center;
    justify-content: center;
    & span {
        font-size: 12px;
        margin-left: 5px;
    }
}

.heart-emoji {
    font-size: 15px;
}
.mobile-body{
    width: 100%;
    background: none;
    border: none;
}
.page-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
}

.main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    max-height: 120vh;
    padding-bottom: 50px;
}
.cards-container-wrapper{
    overflow: auto;
    width: 100%;
}
.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-items: start;
    /*box-sizing: border-box;*/
    width: 100%;
    overflow: auto;
    gap: 25px;
    padding-bottom: 20px;
}

.cards-container-wrapper::-webkit-scrollbar {
    display: none;
}

.filter-tag {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 18px;
    /*
    background-color: #E6F2FF;
    border: solid 1px #1E90FF;
    color: #1E90FF;
    */

    background-color: #E0EBFF;
    color: #1a85ee;
    padding: 6px 15px;
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
    width: fit-content;
    white-space: nowrap;
}

.remove-filter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #7ba6be;
    margin-left: 8px;
}

.selected-filters-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: 100%;
    margin-bottom: 18px;
}

.clear-all-btn {
    background-color: #E87A76;
    border: solid 2px #E87A76;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    padding: 4px 15px;
    height: fit-content;
    width: fit-content;
    white-space: nowrap;

}

.selected-filters {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-y: auto;
    width: 92%;
    height: fit-content;
}

.selected-filters::-webkit-scrollbar {
    display: none;
}

.feedback-click {
    color: #0FA4F8;
}

.feedback-click:hover {
    cursor: pointer;
}

.spinner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.spinner-div {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

}
/*@media (min-width: 768px) {*/
/*    .search-bar-container {*/
/*        display: block; !* Show the element on screens larger than or equal to 768px *!*/
/*    }*/
/*}*/

@media (max-width: 767px) {
    .page-body {
       margin-top: 56px;
    }
    .card-container{
        gap: 6px;
    }
    .filter-button{
        border-radius: 12px;
        background-color: #FFFFFF;
        border: solid 1px #EBF1FA;
        color: black;
        padding: 5px 4px 5px 4px;
        width: 42px !important;
        min-width:42px ;
        height: 44px !important;
        margin-left: 12px;
        margin-right: 0;
        & span {
            /*display: ;*/
        }
    }
    .clear-all-btn{
        margin-left: 12px;
        /*width: 30px;*/
        /*height: 38px;*/
        padding-left: 7px;
        padding-right:7px;
    }
    .mobile-search{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
    }
}
@media (max-width: 672px) {
    .card-container{
        justify-items: center;
    }

}
@media (max-width: 360px) {
    .card-container{

        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    }
}