.simple-slider {
    padding: 20px 20px 20px 20px;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    box-sizing: border-box;
}


.simple-slider .slick-next {
    background-image: url('../../assets/card/left_arrow.svg');
    right: -18px !important;
}

.simple-slider .slick-prev {
    background-image: url('../../assets/card/right_arrow.svg');
    left: -18px !important;
}

.simple-slider .custom-arrow {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slick-track {
    display: flex;
    align-items: center;
}

.slick-slide {
    display: flex;
    justify-content: center;
    opacity: 35%;
    transform: scale(0.6);
    transition: opacity 0.4s linear, transform 0.4s linear;
}

.slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.slick-slide.slick-active {
    opacity: 98%;
    transform: scale(1);
    transition: opacity 0.4s linear, transform 0.4s linear;
}

.slick-next::before {
    content: '';
}

.slick-prev::before {
    content: '';
}

@media only screen and (max-width: 767px) {
    .simple-slider .slick-next, .simple-slider .slick-prev {
        background-image: none;
    }
}

/* CSS classes corresponding to keywordColorMap colors */
.frontend {
    background-color: #E0F8F8;
    border: solid 1px rgba(150, 239, 230, 0.55);
    color: #17524c;
}

.backend {
    background-color: rgba(255, 160, 122, 0.55); /* Light orange */
    border: solid 1px rgba(255, 160, 122, 0.55); /* Matching border color */
    color: #492312; /* Darker orange text */
}

.database {
    background-color: rgba(255, 127, 80, 0.55); /* Light coral */
    border: solid 1px rgba(255, 127, 80, 0.55); /* Matching border color */
    color: #521205; /* Darker coral text */
}

.analytics {
    background-color: rgba(152, 255, 152, 0.55); /* Light green */
    border: solid 1px rgba(152, 255, 152, 0.55); /* Matching border color */
    color: #1a3b1a; /* Darker green text */
}

.devops {
    background-color: rgba(160, 160, 255, 0.55); /* Light blue */
    border: solid 1px rgba(160, 160, 255, 0.55); /* Matching border color */
    color: #1a1a54; /* Darker blue text */
}

.machine_learning {
    background-color: rgba(230, 230, 170, 0.55); /* Light yellow-green */
    border: solid 1px rgba(230, 230, 170, 0.55); /* Matching border color */
    color: #3b3b13; /* Darker yellow-green text */
}

.mobile {
    background-color: rgba(255, 108, 178, 0.55); /* Light pink */
    border: solid 1px rgba(255, 108, 178, 0.55); /* Matching border color */
    color: #46182f; /* Darker pink text */
}

.uiux {
    background-color: rgba(170, 200, 230, 0.55); /* Light blue */
    border: solid 1px rgba(170, 200, 230, 0.55); /* Matching border color */
    color: #142536; /* Darker blue text */
}

.qa {
    background-color: rgba(210, 230, 170, 0.55); /* Light green-yellow */
    border: solid 1px rgba(210, 230, 170, 0.55); /* Matching border color */
    color: #353f16; /* Darker green-yellow text */
}

.default-color {
    background-color: rgba(145, 149, 246, 0.55); /* Light blue */
    border: solid 1px rgba(145, 149, 246, 0.55); /* Matching border color */
    color: #171750; /* Darker blue text */
}

.programming_languages {
    background-color: rgba(145, 149, 246, 0.55); /* Light blue */
    border: solid 1px rgba(145, 149, 246, 0.55); /* Matching border color */
    color: #171750; /* Darker blue text */
}


.slider-element {
    box-sizing: border-box;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    height: 32px;
    max-width: 120px;
    border-radius: 8px;
    margin: 0;
    line-height: 33px;
    vertical-align: middle;
    font-weight: 500;
    font-size: 12px;
    box-shadow: 1px 0 11px 0 rgba(208, 206, 206, 0.51);
    /*
    color: #222;
    */
    width: 120px !important;
}

.simple-slider .slick-dots li button:before {
    color: rgba(7, 76, 255, 0.35);
    opacity: 1;
}

.simple-slider .slick-dots li.slick-active button:before {
    color: rgb(7, 76, 255, 0.95);
}

.simple-slider .slick-dots li button:before {
    font-size: 8px;

}

.slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -36px !important;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.simple-slider .slick-dots li button {
    padding: 0;
}

.simple-slider .slick-dots li button:before {
    font-size: 8px;
    color: #d1e8ff;
}

.simple-slider .slick-dots li.slick-active button:before {
    color: #73ccff
}

.simple-slider .slick-dots li {
    margin: 0 5px !important;
}

.is-scrollable {
    box-shadow: inset -40px 0px 11px -32px rgb(242 242 242 / 55%),
    inset 40px 0px 11px -32px rgb(242 242 242 / 55%);
}

.slider-elements-wrapper {
    position: relative;
    margin: 0 25px 0 25px;
    overflow: auto;
    padding: 10px;
    border-radius: 12px;
}

.slider-elements {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: flex-end;
    align-items: center;
    margin: 0 auto 0 auto;
    height: 50%;
    width: fit-content;
}

.img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #ECEDEC;
    background: #FFF;
    align-self: center;
    margin: 0 10px 0 10px;
    overflow: hidden;
}

.result-item {
    width: 100%;
    object-fit: cover;
}

.slider-elements-wrapper::-webkit-scrollbar {
    display: none;
}

.preview-next-element {
    width: 50px;
}