.footer {
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.85);
    border-top: 2px solid #EBF1FA;
    border-bottom: 2px solid #EBF1FA;
    position: static;
    top: 0;
    bottom: 0;
    height: 135px;
    margin-bottom: 30px;
    font-weight: 300;
    justify-content: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    justify-content: space-around;
    align-items: center;
    overflow: auto;
    width: fit-content;
    .title {
        font-size: 22px;
    }
}

.footer-image {
    width: 140px;
    height: 100%;
    box-sizing: content-box;
    overflow: hidden;
    padding-left: 20px;
    > img {
        width: 100%;
        height: 200px;
        position: relative;
        top: 8px;
        opacity: 0.3;
    }
}

.footer-email {
    color: #0FA4F8;
    font-size: 12px;
    font-weight: 500;

}

.copyright {
    font-size: 10px;
    color: #012970;
}

.social-icons-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: fit-content;
}

.icon {
    margin: 5px;
    height: 18px;
    width: 18px;
    opacity: 0.9;
}
@media (max-width: 767px) {
    .footer{
        margin-bottom:130px
    }
}