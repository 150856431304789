.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 1;
    overflow: auto;
}

.feedback-modal {
    padding: 11px;
    min-height: fit-content;
    background-color: white;
    border-radius: 12px;
    width: calc(50vw + 0vh);
    height: 550px;
    overflow: auto;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 100%;

    & form {
        height: 100%;
        width: 100%;
        min-height: fit-content;
        /*
        background-color: #666666;
        */
    }
}

.modal-header {
    position: sticky;
    min-height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #EBF1FA;

    > * {
        height: fit-content;
        width: fit-content;
    }
}

.modal-title {
    display: flex;
    justify-content: space-around;
    align-items: start;
    align-self: center;
    width: 115px;
    font-weight: 600;
    font-size: 17px;
}

.close-btn {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-form-first-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22%;
    min-height: fit-content;
    padding: 15px 0 10px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #EBF1FA;
    font-weight: 500;

    .question {
        color: #2D2D2D;
        font-size: 15px;
        min-height: fit-content;
    }

    .sub-question {
        color: #6D6D6D;
        font-size: 13px;
        min-height: fit-content;
    }
}

.modal-form-second-part {
    height: 50%;
    padding: 20px 0 10px 10px;
    min-height: fit-content;
}

.modal-form-third-part {
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: fit-content;
    padding: 5px;
    min-height: fit-content;
}

.option {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 65px;
    font-size: 15px;
    & input[type="radio"] {
        border: 3px solid #F1F1F1;
        width: 1.1em;
        height: 1.1em;
    }
}

.options {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.rate-question {
    color: #2D2D2D;
    font-size: 13px;
    font-weight: 500;
    min-height: fit-content;
}

.rate-options {
    > * {
        width: 90px;
        height: 100px;
        min-height: fit-content;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .rate-txt {
        color: #6D6D6D;
        font-weight: 500;
        font-size: 11px;
        text-align: center;
    }

    & input[type="radio"] {
        display: none;
    }

    & label {
        cursor: pointer;
        margin: 20px 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

    & img:hover {
        scale: 1.05;
    }
    & label:hover .option-1,
    & label:hover .rate-txt {
        transform: scale(1.05);
        transition: transform 0.3s;
    }
    & input[type="radio"] + img {
        height: 100px;
        width: 100%;
        border-radius: 100%;
        padding: 2px;
    }

    & input[type="radio"]:checked + img {
        border: 1px solid rgb(8, 18, 255);
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.53);
    }
    & input[type="radio"]:checked ~ .rate-txt{
        scale: 1.05;
    }

    .option-1 {
        background-color: #F3ABA7;
    }

    .option-2 {
        background-color: #F4CE9B;
    }

    .option-3 {
        background-color: #B4E5BC;
    }

    .option-4 {
        background-color: #B8DFF2;
    }

    .option-5 {
        background-color: #CCC1F0;
    }
}

.text-area {
    width: 50%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 2px 2px #EBF1FA;
    font-size: 12px;
    padding: 10px;
    resize: both;
}

.text-area:focus {
    border: none;
    outline: none;
}

.submit-btn {
    background-color: #0FA4F8;
    color: white;
    height: fit-content;
    width: fit-content;
    padding: 8px 17px 8px 17px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
}

.successful-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.successful-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}