.bottom-nav {
    position: fixed;
    bottom: 0;
    height: 101px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #F6F9FF;
    padding: 10px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.nav-link {
    text-decoration: none;
    color: #93A0BB;
    font-size: 13px;
    text-align: center;
}

.nav-link.active {
    color: #2D2D2D;
}

.nav-icon-container {
    width: 60px;
    height: 50px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E6EDF966;
}

.nav-icon-container.active-bg {
    background-color: #D1E8FFF2;
}

.nav-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
}

.nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 111px;
    height: 78px;
}
